<template>
    <div class="row">
        <div class="col-12">
            <div class="d-flex">
                <h4 class="qa-edudex-date-modified">
                    {{ edudexImport && edudexImport.dateModified | formatDate }}
                </h4>

                <h4 class="mx-2">-</h4>

                <h4 v-if="isImportDone" class="qa-edudex-import">
                    {{ this.edudexImport.statusDisplayValue }}
                </h4>

                <h4 v-if="isValidationDone" class="qa-edudex-test">
                    {{ this.edudexImport.statusDisplayValue }}
                </h4>

                <h4 v-if="isInvalid" class="qa-edudex-test">
                    {{ this.edudexImport.statusDisplayValue }}
                </h4>

                <h4 class="mx-2">-</h4>

                <h4 class="qa-edudex-email">{{ setEmailAddress }}</h4>
            </div>
        </div>

        <div class="col-12 mt-2">
            <div class="d-flex">
                <h4 class="u-color-success u-text-bold qa-edudex-success-count">
                    {{ resultCount }}
                </h4>

                <h4 class="mx-2">-</h4>

                <h4 class="u-color-error u-text-bold qa-edudex-result-count">
                    {{ errorCount }}
                </h4>

                <h4 class="mx-2">-</h4>

                <h4 class="qa-edudex-unmodified-count">{{ unmodifiedCount }}</h4>
            </div>
        </div>
    </div>
</template>

<script>
import { getItemById } from '@/../../shared/api/SharedClient'

import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

export default {
    name: 'EdudexImportDetails',
    data() {
        return {
            PROVIDER,
            edudexImport: null
        }
    },
    computed: {
        setEmailAddress() {
            return this.edudexImport &&
                this.edudexImport.emailAddress ===
                    PROVIDER.Portfolio.EdudexFeed.ImportList.AutoImportEmail
                ? PROVIDER.Portfolio.EdudexFeed.ImportList.Automatic
                : this.edudexImport && this.edudexImport.emailAddress
        },
        isCompleted() {
            return (
                this.edudexImport &&
                this.edudexImport.status ===
                    PROVIDER.Portfolio.EdudexFeed.ImportStatus.Completed.Value
            )
        },
        resultCount() {
            return this.isCompleted
                ? this.edudexImport &&
                      `${
                          this.edudexImport.succeeded
                      } ${PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Succeeded.Description.toLowerCase()}`
                : this.edudexImport &&
                      `${
                          this.edudexImport.valid
                      } ${PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Valid.Description.toLowerCase()}`
        },
        errorCount() {
            return this.edudexImport && this.edudexImport.invalid
                ? this.edudexImport.invalid +
                      PROVIDER.Portfolio.EdudexFeed.ImportDetails.FailedTextLabel
                : ''
        },
        unmodifiedCount() {
            return this.edudexImport
                ? `${
                      this.edudexImport.unmodified
                  } ${PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Unmodified.Description.toLowerCase()}`
                : ''
        },
        isInvalid() {
            return (
                this.edudexImport &&
                this.edudexImport.status ===
                    PROVIDER.Portfolio.EdudexFeed.ImportStatus.Invalid.Value
            )
        },
        isImportDone() {
            return (
                this.edudexImport &&
                this.edudexImport.importProgress === '100' &&
                this.edudexImport.status ===
                    PROVIDER.Portfolio.EdudexFeed.ImportStatus.Completed.Value
            )
        },
        isValidationDone() {
            if (
                this.edudexImport &&
                this.edudexImport.validationProgress === '100' &&
                this.edudexImport.status ===
                    PROVIDER.Portfolio.EdudexFeed.ImportStatus.PendingStart.Value
            ) {
                return true
            }

            return false
        }
    },
    mounted() {
        // Code that will run only after the entire view has been rendered
        // We need this otherwise the token will not be sent in the request header through axios
        this.$nextTick(() => {
            this.getEdudexImportById()
        })
    },
    methods: {
        async getEdudexImportById() {
            try {
                const response = await getItemById(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.edudexImports,
                    this.$route.params.id,
                    null,
                    false
                )
                if (!response) return
                this.edudexImport = response
            } catch (error) {
                console.error('Something went wrong while retrieving edudex import details', error)
            }
        }
    }
}
</script>